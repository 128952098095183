@import 'src/variables.scss';

.root {
  align-items: center;
  bottom: 30px;
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 28px;

  &:link, &:visited {
    text-decoration: inherit;
    color: inherit;
  }
}

.avatar {
  background-size: cover;
  border-radius: 50%;
  height: 32px;
  width: 32px;
}

.info {
  font-family: $fontFamilyMedium;
  font-size: 13px;
  margin: 0 12px;
}

.author {
  align-items: center;
  display: flex;
  font-size: 18px;
  line-height: 24px;
}

.link {
  margin-left: 10px;
  width: 14px;
}

@media (max-width: 720px) {
  .root {
    right: 14px;
  }

  .info {
    margin: 0 0 0 12px;
  }
}

@media (max-height: 550px) {
  .root {
    bottom: 12px;
  }
}

@media (max-height: 516px) {
  .root {
    display: none;
  }
}
