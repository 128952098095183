@import 'src/variables.scss';

.root {
  backdrop-filter: blur(22px);
  background: rgba(51, 51, 51, 0.75);
  border-radius: $componentRadius;
  height: 380px;
  width: 280px;
  z-index: 2;
}

.wide {
  height: auto;
  //height: 74%;
  //max-height: 740px;
  max-height: 86%;
  width: 460px;
  display: flex;
}

@media (max-width: 720px) {
  .wide {
    width: 100%;
  }
}

@media (max-height: 810px) {
  .wide {
    max-height: 80%;
  }
}

@media (max-height: 680px) {
  .wide {
    max-height: 77%;
  }
}
