@import 'src/variables.scss';

.root {
  background: linear-gradient(21.32deg, rgba(0, 0, 0, 0.75) 52.49%, rgba(0, 0, 0, 0) 100%), 50%;
  background-size: cover !important;
  height: 100vh;
  padding: 16px 24px;
  position: relative;
}

.widget {
  align-items: center;
  display: flex;
  justify-content: left;
  height: 100%;
  margin-top: -44px;
}

.childWidget {
  order: 1;
}

.description {
  color: #fff;
  margin-left: 24px;
  order: 2;
  width: 370px;
}

.title {
  font-family:  $fontFamilyBold;
  font-size: 18px;
}

.slogan {
  font-family:  $fontFamilyBold;
  font-size: 46px;
  line-height: 48px;
  margin: 24px 0;
  max-width: 350px;
}

.text {
  font-family:  $fontFamilyNormal;
  font-size: 16px;
}

.hint {
  bottom: 36px;
  cursor: pointer;
  position: absolute;
}

.wide {
  .description {
    display: none;
  }
}

@media (max-width: 720px) {
  .root {
    padding: 8px 14px;
  }

  .title, .text {
    display: none;
  }

  .slogan {
    font-size: 32px;
    margin: 80px 0 20px;
    max-width: 100%;
  }

  .widget {
    flex-direction: column;
  }

  .description {
    margin-left: 0;
    margin-top: 0;
    max-width: 100%;
    order: 1;
    text-align: center;
    width: 100%;
  }

  .childWidget {
    order: 2;
  }

  .wide {
    &.root {
      padding: 2px 14px;
    }

    .description {
      display: block;
    }

    .title, .text {
      display: none;
    }

    .childWidget {
      max-height: 76%;
    }

    .footer {
      display: none;
    }
  }
}

@media (max-width: 368px) {
  .root {
    padding: 2px 8px;
  }

  .description {
    margin-top: 0;
  }

  .slogan {
    font-size: 28px;
  }
}

@media (max-width: 316px) {
  .slogan {
    font-size: 24px;
  }
}

@media (min-height: 750px) and (max-width: 720px) {
  .slogan {
    margin: 110px 0 80px;
  }
}

@media (max-height: 630px) {
  .root {
    padding-top: 0;
  }

  .slogan {
    margin: 60px 0 16px;
  }
}

@media (max-height: 580px) {
  .slogan {
    margin: 50px 0 4px;
    font-size: 24px;
  }
}

@media (max-height: 550px) {
  .slogan {
    margin: 44px 0 0;
    font-size: 20px;
  }

  .hint {
    bottom: 20px;
  }
}
