@import 'src/variables.scss';

button.root {
  font-size: 16px;
  line-height: 20px;
  padding: 16px 32px;

  &:disabled {
    opacity: 0.2;
  }
}

button.primary {
  background: $primary;
}

button.grey {
  background: rgba(255, 255, 255, 0.3);
  color: #fff;
}
