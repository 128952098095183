@import 'src/variables.scss';

.root {
  padding: 18px;
  width: 100%;
}

.body {
  height: calc(100% - 52px);
  position: relative;
  display: flex;
  flex-direction: column;
}

.form {
  background: #393939;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  margin: 26px 0 24px;
  padding: 16px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    margin-left: 20px;
    padding-left: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 5px;
  }
}

.imageContainer {
  align-items: center;
  display: flex;
  height: 70px;
  margin-bottom: 8px;
  justify-content: center;
}

.imageInfo {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  margin-left: 12px;
  min-width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.imageName, .imageSize, .imageLink {
  font-family: $fontFamilyBold;
  font-size: 14px;
  line-height: 17px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.imageSize {
  font-family: $fontFamilyNormal;
  margin-top: 6px;
}

.imageLink {
  color: $primary;
  cursor: pointer;
  display: flex;
  margin-top: auto;

  &:link, &:visited {
    text-decoration: inherit;
    color: $primary;
  }
}

.image {
  max-height: 100%;
  max-width: calc(100% - 92px);
}

.formItem {
  margin-top: 16px;
  font-size: 14px;

  .label {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    letter-spacing: 0.15px;
    margin-top: 0;
  }

  :global {
    .alteon-input-wrapper, .alteon-textarea-container {
      label {
        color: rgba(255, 255, 255, 0.6);
        font-size: 12px;
        letter-spacing: 0.15px;
        margin-top: 0;
      }

      input {
        font-size: 16px;
        letter-spacing: 0.15px;
      }
    }

    .alteon-input {
      margin-top: 4px;
    }

    .alteon-tag-input {
      display: flex;
      min-height: 24px;
      padding: 0;
    }

    .alteon-tags-tag {
      margin: 0;
    }

    .alteon-tag-title {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.15px;
    }

    .alteon-tags-close-icon, .alteon-tags-add-icon, .alteon-tags-add-icon-active-input {
      height: 16px;
      width: 16px;
    }

    .alteon-tag-filled {
      padding: 4px 4px 4px 10px;
    }

    .alteon-stepper-pair-stepper {
      margin: 0;
    }
  }

  textarea.area {
    border: none;
    box-sizing: border-box;
    resize: none;
    overflow: hidden;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    margin-top: 10px;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-top: 0 !important;

    &:focus {
      outline: none;
      border: none;
    }
  }
}

.label {
  margin-bottom: 6px;
}

.line {
  height: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.42);
}

.footer {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .tooltip {
    background: linear-gradient(0deg, #000000, #000000), #fff;
    border-radius: 8px;
    color: #fff;
    max-width: 256px;
    mix-blend-mode: normal;
    padding: 12px;
    font-size: 12px;
    line-height: 14px;
  }
}

.confirm {
  margin-left: 16px;
}

.block {
  align-items: center;
  display: flex;
}

.ethereum {
  align-items: center;
  display: flex;
  background: #151C2F;
  border-radius: 50%;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-right: 6px;
}

.ethereumLogo {
  background: url('/images/ethereum-colored.png');
  background-size: cover;
  border-radius: 50%;
  height: 18px;
  width: 18px;
}

.plus, .minus {
  user-select: none;
}

.minus {
  height: 17px;
  width: 17px;
}

.quantityContainer {
  align-items: center;
  display: flex;

  .label {
    margin-bottom: 0;
    margin-right: 20px;
  }

  .unit {
    border-radius: 8px;
    height: 40px;
  }
}
