@import 'src/variables.scss';

div.modalHeader {
  font-family: $fontFamilyBold;
  font-size: 20px;
  display: flex;
  padding-bottom: 18px;

  :global {
    .alteon-modal-close {
      top: 1px;
    }

    .alteon-modal-close-svg {
      height: 22px;
      width: 22px;
    }
  }
}

div.modalContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 32px;
}

div.modalContainer {
  background: #313131;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 32px;
  min-width: 250px;
}

@media (max-width: 460px) {
  div.modalContainer {
    margin: 20px;
  }
}

@media (max-width: 390px) {
  div.modalHeader {
    border-bottom: none;
    padding-bottom: 0;

    .icon {
      display: none;
    }

    :global {
      .alteon-modal-close {
        display: none;
      }
    }
  }

  div.modalContent {
    padding-top: 24px;
  }
}

.icon {
  margin-right: 16px;
  svg {
    height: 22px;
    width: 22px;
  }
}

.body {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.15px;
}

.footer {
  display: flex;
  margin-top: 32px;
  justify-content: right;
}

.link {
  cursor: pointer;
  color: $primary;
  padding: 0 5px;
  text-decoration: none;

  &:link, &:visited {
    color: $primary;
    text-decoration: none;
  }
}
