@import 'src/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 18px;
  text-align: center;
}

.wallet {
  display: flex;
  height: 72px;
  justify-content: center;
  margin-bottom: 30px;

  svg {
    width: 72px;
    height: 72px;
  }
}

.title {
  font-size: 20px;
  font-family: $fontFamilyBold;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.desc {
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  line-height: 18px;
  margin: 8px 0 20px;
  white-space: pre-line
}

.cond {
  letter-spacing: -0.453436px;
}
