.root {
  align-items: center;
  background: #606060;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 1001;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.6;
}

.loader {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: conic-gradient(from 90deg at 50% 50%, rgba(39, 174, 96, 0) 0deg, rgba(14, 211, 223, 0) 0.04deg, #0ED3DF 360deg);
  box-shadow: 0px 0px 100px -50px black;
  animation: animate 1s linear infinite;
}

.loader::before {
  position: absolute;
  content: "";
  background: #606060;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 86%;
  height: 86%;
  border-radius: 50%;
  box-shadow: inset 0px 0px 100px -70px #111;
}

@keyframes animate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  width: 60px;
  height: 60px;
}
