@import 'src/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 18px;
  text-align: center;
}

.ok {
  display: flex;
  height: 79px;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 6px;

  svg {
    width: 79px;
    height: 79px;
  }
}

.title {
  font-size: 20px;
  font-family: $fontFamilyBold;
  line-height: 24px;
  letter-spacing: -0.02em;
}

.desc {
  align-items: center;
  color: rgba(255, 255, 255, 0.75);
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin: 12px 0 20px;
  white-space: pre-line;
  letter-spacing: -0.02em;
  line-height: 18px;
}

.link {
  display: flex;
  font-size: 14px;
  font-family: $fontFamilyBold;
  line-height: 17px;
  margin-left: 5px;
  color: $primary;
  cursor: pointer;
  justify-content: center;
  text-decoration: none;

  &:link, &:visited {
    color: $primary;
    text-decoration: none;
  }

  svg {
    margin-left: 10px;
  }
  path {
    stroke: $primary;
  }
}
