.root {
  display: flex;
}

.logo {
  height: 32px;
  margin-top: 12px;
  width: 223px;

  img {
    height: 100%;
  }
}

@media (max-width: 720px) {
  .logo {
    height: 28px;
  }
}

@media (max-width: 368px) {
  .logo {
    height: 24px;
  }
}

@media (max-height: 580px) {
  .logo {
    height: 24px;
  }
}

@media (max-height: 550px) {
  .logo {
    height: 21px;
  }
}
