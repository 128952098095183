@font-face {
  font-family: 'HelveticaNeueBold';
  font-style: normal;
  font-weight: bold;
  src: url('./HelveticaNeueBold.eot');
  src: url('./HelveticaNeueBold.eot?#iefix') format('embedded-opentype'),
    url('./HelveticaNeueBold.woff2') format('woff2'),
    url('./HelveticaNeueBold.woff') format('woff'),
    url('./HelveticaNeueBold.ttf') format('truetype'),
    url('./HelveticaNeueBold.svg#HelveticaNeueBold') format('svg');
}

@font-face {
  font-family: 'HelveticaNeue';
  font-style: normal;
  font-weight: normal;
  src: url('./HelveticaNeue.eot');
  src: url('./HelveticaNeue.eot?#iefix') format('embedded-opentype'),
    url('./HelveticaNeue.woff2') format('woff2'),
    url('./HelveticaNeue.woff') format('woff'),
    url('./HelveticaNeue.ttf') format('truetype'),
    url('./HelveticaNeue.svg#HelveticaNeue') format('svg');
}

@font-face {
  font-family: 'HelveticaNeueMedium';
  font-style: normal;
  font-weight: 500;
  src: url('./HelveticaNeueMedium.eot');
  src: url('./HelveticaNeueMedium.eot?#iefix') format('embedded-opentype'),
    url('./HelveticaNeueMedium.woff2') format('woff2'),
    url('./HelveticaNeueMedium.woff') format('woff'),
    url('./HelveticaNeueMedium.ttf') format('truetype'),
    url('./HelveticaNeueMedium.svg#HelveticaNeueMedium') format('svg');
}

