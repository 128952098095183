@import 'src/variables.scss';

.root {
  align-items: center;
  display: flex;
  font-family: $fontFamilyBold;
  font-size: 20px;
}

.headerIcon {
  margin-right: 20px;
}
