@import 'src/variables.scss';

div.modalHeader {
  font-family: $fontFamilyBold;
  font-size: 20px;
  display: flex;
  padding-bottom: 18px;

  .alteon-modal-close {
    top: 1px;
  }

  .alteon-modal-close-svg {
    height: 22px;
    width: 22px;
  }
}

div.modalContent {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding-top: 32px;
}

div.modalContainer {
  background: #313131;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding: 32px;
  max-width: calc(100% - 20px);
}

.question {
  margin-right: 16px;

  svg {
    height: 22px;
    width: 22px;
  }
}

.back {
  cursor: pointer;
  user-select: none;
  margin-right: 16px;

  svg {
    height: 24px;
    width: 24px;
  }

}

.video {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
  height: 360px;
  width: 640px;
}

.contentContainer {
  overflow-y: auto;
  overflow-x: hidden;
  height: 360px;
  width: 640px;

  p {
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    color: #86868B;
    width: 99%;

    i {
      color: #fff;
      font-size: 17px;
      font-style: normal;
    }
  }

  &::-webkit-scrollbar {
    width: 6px;
    margin-left: 20px;
    padding-left: 20px;
  }

  &::-webkit-scrollbar-track {
    background-color: $whiteTransparent10;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 5px;
  }
}

.policy {
  a {
    color: $primary;
    text-decoration: none;

    &:link, &:visited {
      color: $primary;
      text-decoration: none;
    }
  }

  li {
    margin: 12px 0;
  }
}

.title {
  font-size: 24px;
  margin-bottom: 16px;
}

.subTitle {
  color: #fff;
  font-weight: 500;
}

.modalTitle {
  display: flex;
  align-items: center;
  max-width: calc(100% - 40px);
}

@media (max-width: 720px) {
  .video {
    height: calc(80vw / 1.77);
    width: 80vw;

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  .policy {
    overflow-wrap: break-word;
    width: 80vw;
  }
}

.footer {
  display: flex;
  margin-top: 17px;
  justify-content: end;
}

.link {
  color: #777777;
  font-size: 13px;
  font-weight: 700;
  line-height: 24px;
  margin-right: 16px;

  &.active {
    color: #fff;
  }

  &:link, &:visited {
    text-decoration: inherit;
    color: #777777;
    &.active {
      color: #fff;
    }
  }
}
