@import 'src/variables.scss';

.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  padding: 18px;
}

.desc {
  color: $whiteTransparent75;
  font-size: 14px;
  margin: 26px 0 20px;
}

.dropArea>.drop {
  background: $whiteTransparent10;
  border: 2px dashed #fff;
  border-radius: 0 !important;
  cursor: pointer;
  height: 122px;
  width: 248px;
}

.dropText {
  cursor: pointer;
  font-family: $fontFamilyBold;
  font-size: 16px;
}

.error {
  color: #D45032;
  font-size: 14px;
  margin-top: 2px;
}

.imageContainer {
  display: flex;
  justify-content: center;
}

.image {
  max-height: 122px;
  max-width: 248px;
}
