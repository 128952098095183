@import 'src/variables.scss';

.root {
  font-family: $fontFamilyMedium;
  font-size: 16px;
  line-height: 24px;
  position: relative;

  &:focus-visible {
    outline: none;
  }
}

.value {
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.42);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  user-select: none;
  padding: 6px 0;
}

.name {
  align-items: center;
  display: flex;
}

.menu {
  background: #555555;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  display: none;
  list-style: none;
  margin: 4px 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 100%;

  &.visible {
    display: block;
  }

  >li {
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    padding: 6px 8px;
    user-select: none;
  }
}

.active {
  background: $whiteTransparent10;
}

.icon {
  height: 20px;
  margin-right: 12px;
}

.caret {
  height: 20px;

  &.open {
    transform: rotate(180deg);
  }
}

.label {
  color: rgba(255, 255, 255, 0.6);
  font-family: $fontFamilyNormal;
  font-size: 12px;
  letter-spacing: 0.15px;
  margin: 0;
}
